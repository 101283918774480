import Main from "@/layout/main.vue";
const routes = [
  {
    path: "/model",
    name: "model",
    meta: { title: "模型" },
    redirect: "/model/model_list",
    component: Main,
    children: [
      {
        path: "/model/model_list",
        name: "model_list",
        meta: {
          title: "模型列表",
          parentPath: "/model",
          icon: "",
          permission: ["view"],
          keepAlive: true,
        },
        component: () => import("@/views/model/model_list.vue"),
      },
      {
        path: "/model/model_edit",
        name: "model_edit",
        meta: {
          hidden: true,
          title: "模型编辑",
          parentPath: "/model",
          prevPath: "/model/model_list",
        },
        component: () => import("@/views/model/model_edit.vue"),
      },
      {
        path: "/model/category",
        name: "model_category",
        meta: {
          title: "模型分类",
          parentPath: "/model",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/model/category.vue"),
      },
      {
        path: "/model/category_edit",
        name: "model_category_edit",
        meta: {
          hidden: true,
          title: "分类编辑",
          parentPath: "/model",
          prevPath: "/model/model_list",
        },
        component: () => import("@/views/model/category_edit.vue"),
      },
      {
        path: "/model/material_quality",
        name: "material_quality",
        meta: {
          title: "模型材质",
          parentPath: "/model",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/model/material_quality.vue"),
      },
      {
        path: "/model/color",
        name: "model_color",
        meta: {
          title: "颜色库",
          parentPath: "/model",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/model/color.vue"),
      },
      {
        path: "/model/agreement",
        name: "model_agreement",
        meta: {
          title: "模型协议",
          parentPath: "/model",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/model/agreement.vue"),
      },
      {
        path: "/model/apply",
        name: "model_apply",
        meta: {
          title: "建模申请",
          parentPath: "/model",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/model/apply.vue"),
      },
      {
        path: "/model/apply_edit",
        name: "model_apply_edit",
        meta: {
          title: "编辑申请",
          parentPath: "/model",
          icon: "",
          permission: ["view"],
          hidden: true,
        },
        component: () => import("@/views/model/apply_edit.vue"),
      },
      {
        path: "/model/template_list",
        name: "model_template_list",
        meta: {
          title: "建模模板",
          parentPath: "/model",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/model/template_list.vue"),
      },
      {
        path: "/model/template_edit",
        name: "model_template_edit",
        meta: {
          title: "编辑模板",
          parentPath: "/model",
          icon: "",
          permission: ["view"],
          hidden: true,
        },
        component: () => import("@/views/model/template_edit.vue"),
      },
    ],
  },
];

export default routes;
