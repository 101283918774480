import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
  {
    path: "/design",
    name: "design",
    meta: { title: "设计记录" },
    component: Main,
    redirect: "/design/task",
    children: [
      {
        path: "/design/task",
        name: "design_task",
        meta: {
          title: "效果图列表",
          parentPath: "/design",
          permission: ["view"],
        },
        component: () => import("@/views/design/task.vue"),
      },
      {
        path: "/design/factory-task",
        name: "factory-task",
        meta: {
          title: "稿件列表",
          parentPath: "/design",
          permission: ["view"],
        },
        component: () => import("@/views/design/factory-task.vue"),
      },
      // {
      //   path: "/design/task-1",
      //   name: "design_task_1",
      //   meta: {
      //     title: "测试",
      //     parentPath: "/design",
      //     permission: ["view"],
      //   },
      //   component: () => import("@/views/design/task-1.vue"),
      // },
    ],
  },
];

export default routes;
