// 开发环境域名
const host_development = 'https://fqjczclzwll.saasdiy.com'
// const host_development = 'https://nsaas.shopape.cn'
// admin fq111213

export default {
  // 版本
  version: '3.1.0',
  baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
  tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
